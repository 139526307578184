/* App.css */
html * {
  font-family: 'Montserrat', sans-serif;
 
}

:root {
  --wazzaGroen: ##1cac53;
  /* Blue color */
}

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000000;
  overflow-x: hidden;
}

a:link {
  color: #1cac53;
}

.App {
  display: flex;
  flex-direction: column;
  color: #fff;
  min-height: 100vh;
  /* Use min-height instead of height to ensure it expands as needed */
  background-color: #000000;
  
}

::-webkit-scrollbar {
  display: none;
}

body {
  scrollbar-width: none;
}
html {
  scrollbar-width: none;
}

.Section {
  background-color: #000000;
  color: #fff;
  width: 100%;
}

.Content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Text {
  max-width: 50%;
}

h2,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

h2 {
  text-align: center;
}

ul {
  list-style-type: none;
}

/* ---------------------------Login page---------------------------------------*/
.Loginpage {
  background-color: #000000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  /* Set height to 100% of viewport height */
  transition: height 1s ease-in-out;
  /* Add transition for height */
}

.LoginLogo {
  background-color: #000000;
  order: -1;
  /* Set the order to -1 to ensure the image appears first */
  height: auto;
  justify-content: center;
  width: 30%;
}

/* ---------------------------Admin Page---------------------------------------*/
.AdminPage {
  background-color: #000000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.AdminLogo {
  height: auto;
  max-width: 20%;
}

.AdminHeader {
  background-color: #000000;
  color: #fff;
  display: flex;
  top: 0;
  width: 100%;
  padding: 0%;
  justify-content: space-between;
  align-items: center;
}
.smallLogo {
  margin-left: 10px; /* Adjust this value as needed */
}

/* Styling for the button */
.LogoutButtonContainer {
  margin-right: 20px; /* Adjust this value as needed */
  margin-top: 10px;
}

.ReportsContainer {
  color: #000000;
  background-color: #f2f4f2;
  width: 98%;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 5%;
}
.ReportsContainer h2{
  color: #000000;
  text-align: left;
  padding: 2%;
}
.userReportsGrid{
  color: #fff;
  width: 100%;
}

/* ---------------------------HEADER---------------------------------------*/
.Header {
  background-color: #000000;
  color: #fff;
  display: flex;
  padding: 20px 50px;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0%;
}

.Header img {
  height: auto;
  max-width: 20%;
  opacity: 0;
  /* Initially hide the image */
  transition: opacity 0.5s ease;
  /* Add transition effect */
}

.SplashScreen {
  background-color: #000000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  /* Set height to 100% of viewport height */
  transition: height 1s ease-in-out;
  /* Add transition for height */
}

.SplashLogo {
  background-color: #000000;
  order: -1;
  /* Set the order to -1 to ensure the image appears first */
  height: auto;
  justify-content: center;
  width: 80%;
}

/* Fade-in animation for tagline */
.Tagline {
  transition: opacity 1s ease-in-out;
  /* Add transition for opacity */
  opacity: 0;
  text-align: center;
  font-size: 36px;
  /* Increase the font size */
  height: auto;
  /* Initially hide tagline */
}

.ShowTagline {
  opacity: 1;
  /* Make tagline visible */
  height: auto;
  /* Set height to auto */
}

/* Responsive font size for header */
@media screen and (max-width: 768px) {
  .Tagline {
    font-size: 24px;
    /* Adjust font size for smaller screens */
  }
}

/* Media query for smaller screen sizes */
@media screen and (max-width: 500px) {
  .logo.loaded {
    /* Limit image size on smaller screens */
    max-width: 30%;
    /* Adjust the percentage as needed */
    height: auto;
  }
}

.logo.loaded {
  opacity: 1;
  /* Show the image when loaded */
}





/* ---------------------------DOWNLOAD---------------*/
.DownloadSection {
  background-color: #000000;
  color: #fff;
  text-align: center;

  padding-top: 5%;
  padding-bottom: 5%;
}

.DownloadButtonsContainer {
  background-color: #000000;
  display: flex;
  justify-content: center;
  /* Align horizontally in the center */
  align-items: center;
  /* Align vertically in the center */
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
}

/* Define vertical alignment for smaller screens */
@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }
}

.DownloadImageContainer {
  display: flex;
  justify-content: center;
  /* Align horizontally in the center */
  align-items: center;
  /* Align vertically in the center */
  width: 100%;
  min-height: 100%;
  background-color: #000000;
}

.DownloadImageContainer img {
  /*allows responsiveness*/
  width: 52%;
  height: auto;
  padding: 5%;
}

.DownloadButtons {
  /*Centres image in the div*/
  text-align: center;
}

.GooglePlayButton,
.AppStoreButton {

  background-color: transparent;
  border: none;
  cursor: pointer;

}

.GooglePlayButton img,
.AppStoreButton img {
  /*allows responsiveness*/
  max-width: 50%;
  height: auto;

}

.DownloadHeader {
  max-width: 100%;
  font-size: 85px;
  /* Increase the font size */
  text-align: center;
}

/* Responsive font size for header */
@media screen and (max-width: 768px) {
  .DownloadHeader {
    font-size: 36px;
    /* Adjust font size for smaller screens */
  }
}

/* ---------------------------FEATURES-----------------------------------------------------------------*/
.FeatureSection {
  background-color: #272424;
  color: #e0dbdb;
  max-width: 100%;
}

.Tablist {
  width: 100%;
}

.FeatureTextContainer {
  width: 100%;
  height: 100%;
  font-size: large;
}

.FeatureImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.FeatureImageContainer img {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  height: auto;
}

.LegacyImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.LegacyImageContainer img {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  height: auto;
}


.FeatureHeader {
  font-size: 48px;
  /* Set base font size */
  line-height: 1.5;
  /* Set base line height */
}

.FeatureText {
  font-size: 36px;
  /* Set base font size */
  line-height: 1.5;
  /* Set base line height */
}

/* Media query for small screens */
@media screen and (max-width: 900px) {
  .FeatureHeader {
    font-size: 22px;
    /* Decrease font size for smaller screens */
  }

  .FeatureText {
    font-size: 16px;
    /* Decrease font size for smaller screens */
  }
}

/* Media query for extra small screens */
@media screen and (max-width: 500px) {
  .FeatureHeader {
    font-size: 17px;
    /* Further decrease font size for extra small screens */
  }

  .FeatureText {
    font-size: 11px;
    /* Further decrease font size for extra small screens */
  }
}

/* ---------------------------Coverage-----------------------------------------------------------------*/
.CoverageSection {
  background-color: #b2beb7;
  color: #292727;
  padding-top: 5%;
  padding-bottom: 5%;
  max-width: 100%;
}

.worldmap {
  width: 100%;
  height: auto;
  padding: 2%;
}

.CoverageTextContainer {
  width: 100%;
  height: 100%;
  font-size: large;
  text-align: center;
  display: flex;
  /* Use flexbox for positioning */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */

}

.CoverageHeader {
  font-size: 48px;
  /* Set base font size */
  line-height: 1.5;
  /* Set base line height */
}

.CoverageText {
  font-size: 36px;
  /* Set base font size */
  line-height: 1.5;
  /* Set base line height */
  padding-left: 5%;
  padding-right: 5%;
}

/* Media query for small screens */
@media screen and (max-width: 900px) {
  .CoverageHeader {
    font-size: 22px;
    /* Decrease font size for smaller screens */
  }

  .CoverageText {
    font-size: 16px;
    /* Decrease font size for smaller screens */
  }
}

/* Media query for extra small screens */
@media screen and (max-width: 500px) {
  .CoverageHeader {
    font-size: 17px;
    /* Further decrease font size for extra small screens */
  }

  .CoverageText {
    font-size: 11px;
    /* Further decrease font size for extra small screens */
  }
}

/* ---------------------------About us-----------------------------------------------------------------*/
.AboutSection {
  background-color: #000000;
  color: #ffffff;
  padding-top: 5%;
  padding-bottom: 5%;
  max-width: 100%;
}

.AboutImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.aboutusimage {
  width: 100%;
  height: auto;
  padding: 2%;
}

.AboutTextContainer {
  width: 100%;
  height: 100%;
  font-size: large;

}

.AboutHeader {
  font-size: 48px;
  /* Set base font size */
  line-height: 1.5;
  /* Set base line height */
  padding-left: 5%;
  padding-right: 5%;
}

.AboutText {
  font-size: 25px;
  /* Set base font size */
  line-height: 1.5;
  /* Set base line height */
  padding-left: 5%;
  padding-right: 5%;
}

/* Media query for small screens */
@media screen and (max-width: 900px) {
  .AboutHeader {
    font-size: 22px;
    /* Decrease font size for smaller screens */
  }

  .AboutText {
    font-size: 16px;
    /* Decrease font size for smaller screens */
  }
}

/* Media query for extra small screens */
@media screen and (max-width: 500px) {
  .AboutHeader {
    font-size: 17px;
    /* Further decrease font size for extra small screens */
  }

  .AboutText {
    font-size: 11px;
    /* Further decrease font size for extra small screens */
  }
}

/* ---------------------------Coverage-----------------------------------------------------------------*/
/* ---------------------------Footer-----------------------------------------------------------------*/

.footer {
  position: relative;
  width: 100%;
  background-color: #000; /* Background color of the footer */
  padding: 20px 0;
  color: #fff;
  text-align: center;
}

.footer-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333; /* Color of the horizontal line */
}

.footer-content {
  text-align: center;
  padding-top: 10px; /* Adjust as needed to give space below the line */
}

.footer-link {
  display: block; /* Makes it appear on a new line below the text */
  margin-top: 10px; /* Space above the link */
  color: #007bff; /* Link color */
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline; /* Underline on hover */
  color: #0056b3; /* Darker shade for hover */
}
/* ---------------------------Privacy policy-----------------------------------------------------------------*/
.privacy-policy {
  display: flex;
  flex-direction: column;
  color: #fff;
  min-height: 100vh;
  /* Use min-height instead of height to ensure it expands as needed */
  background-color: #000000;

  
}
.privacyText {
  word-wrap: break-word;
  white-space: pre-wrap;
}